"use client"

import React, { useEffect, useState, useRef, useCallback } from "react"
import { useSearchParams } from "next/navigation"
import { useTracking } from "react-tracking"

import { Environment } from "../../types"
import { useRuntimeConfig } from "../runtimeConfig"
import { useConsent } from "../consent"
import TrackingContext, { ITrackingContext } from "./TrackingContext"
import {
  TTrackingProperties,
  TTrackingPropertyName,
  IDispatchTrackingData,
} from "./types"
import { getInitTraits } from "../../utils/tracking"

const TrackingProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { clientTrackingConsentIsGiven, integrations } = useConsent()
  const {
    RUDDERSTACK_IS_ENABLED,
    RUDDERSTACK_WRITE_KEY,
    ENVIRONMENT,
  } = useRuntimeConfig().getRuntimeConfig()
  const { trackEvent } = useTracking<IDispatchTrackingData>()
  const searchParams = useSearchParams()

  const [clientTrackingIsReady, setClientTrackingIsReady] = useState(false)

  const properties = useRef<TTrackingProperties>({})

  useEffect(() => {
    if (
      clientTrackingConsentIsGiven &&
      RUDDERSTACK_IS_ENABLED &&
      !clientTrackingIsReady
    ) {
      window.rudderanalytics?.load(
        RUDDERSTACK_WRITE_KEY,
        "https://rudderstack.adviqodev.de",
        {
          logLevel: ENVIRONMENT === Environment.PRODUCTION ? "WARN" : "DEBUG",
          sessions: {
            autoTrack: true,
            timeout: 1800000, // 30 mins
          },
          anonymousIdOptions: {
            autoCapture: {
              enabled: true,
              source: "rudderstack",
            },
          },
          uaChTrackLevel: "full",
          integrations,
          useGlobalIntegrationsConfigInEvents: true,
        }
      )

      window.rudderanalytics?.ready(() => {
        trackEvent({
          method: "identify",
          userId: undefined,
          traits: getInitTraits(searchParams),
        })

        setClientTrackingIsReady(true)
      })
    }
  }, [
    ENVIRONMENT,
    RUDDERSTACK_IS_ENABLED,
    RUDDERSTACK_WRITE_KEY,
    clientTrackingConsentIsGiven,
    clientTrackingIsReady,
    integrations,
    searchParams,
    trackEvent,
  ])

  const getProperty = useCallback(
    (propertyName: TTrackingPropertyName) => properties.current[propertyName],
    []
  )

  const setProperties = useCallback((newProperties: TTrackingProperties) => {
    properties.current = {
      ...properties.current,
      ...newProperties,
    }
  }, [])

  const value: ITrackingContext = {
    clientTrackingIsReady,
    getProperty,
    setProperties,
    trackEvent,
  }

  return (
    <TrackingContext.Provider value={value}>
      {children}
    </TrackingContext.Provider>
  )
}

export default TrackingProvider
