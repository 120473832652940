export const SWR_KEYS = {
  ADVISOR_PROFILE: "advisor",
  ADVISOR_REVIEWS: "advisor/reviews",
  PAYMENT_METHODS: "payment/methods",
  CALL_QUEUES: "call-queues",
  CALL_QUEUE: "call-queue",
  FRESHCHAT_RESTORE_ID: "freshchat/restore-id",
  ACCOUNT_MOVEMENTS_LIST: "account-movements/customer",
  EXPERT_ONLINE_STATUSES: "advisor/list/statuses",
  CUSTOMER_INBOX: "customer-inbox",
  UPDATE_PHONE_NUMBER: "phone",
  GET_PHONE_NUMBER_STATUS: "phone/check-is-valid",
}
